import React, {useState} from "react";

import "./Disclaimer.scss";

const Disclaimer = () => {
  const [disclaimerOpen, setDisclaimerOpen] = useState(true);

  return disclaimerOpen ?
      <div className="disclaimerWrapper">
      <div className="disclaimer" role="banner">
        <div className="disclaimerIconBox"><div className="disclaimerIcon">!</div></div>

        <div className="disclaimerText"><p>Diese Anwendung ist experimentell! Es wird keine Gewähr für die Richtigkeit übernommen! Im Zweifel sind die ausgegebenen Informationen eigenständig zu überprüfen.</p></div>
        <button className="close-button" onClick={() => setDisclaimerOpen(false)} aria-label="Close Disclaimer" title="Disclaimer schließen" tabIndex={0}>
            &#10005;
        </button>
    </div>
      </div>

    :
    <></>
}

export default Disclaimer;
