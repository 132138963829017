import {React} from "react";
import Message from "../message/Message";
import './MessageBox.scss';


const loading = <div className="loadingPos"><div className="lds-ellipsis">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div></div>


const MessageBox = ({messages, submitFeedback, isLoading}) => {
  return (
    <div className="messageScrollBox" id="messageList">
      {
        messages.map(msg => <Message 
          type={msg.type} 
          key={msg.id}
          responseId={msg.type === "messageBot" ? msg.id : undefined} 
          content={msg.content} 
          submitFeedback={submitFeedback}
        />)
      }
      {isLoading && loading}
    </div>
  );
}



export default MessageBox;