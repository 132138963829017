import React, { useState } from "react";
import './Headline.scss';
import ImbitNextLogo from '../../assets/imbitnext.png'
const Headline = () => {


  return (
    <div className="headline" role="heading" aria-level={1}>
      <div className="logos" role="img">
        <img className="dhbwlogo" src={ImbitNextLogo} alt="Eviden Logo"/>
      </div>
        <h3>CHATBOT</h3>


    </div>
  )
}

export default Headline;