import React, {useState} from "react";
import AutoLinkText from 'react-autolink-text2';
import './Message.scss';

const Message = ({type, content, responseId, submitFeedback}) => {
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(0);
  let contentWithoutSource = ""
  let contentSource = ""

  if(type === "messageBot") {

    for(let i = content.length; i > 0; i--) {

      if(content.substring(i).includes("Quelle")) {
        contentSource = content.substring(i);
        contentWithoutSource = content.substring(0,i);
        break;
      }
      if(contentWithoutSource.length === 0) {
        contentWithoutSource = content;
      }
    }
  }

  function removeBrAroundUl(elements) {
    return elements.reduce((acc, curr, index) => {
      const prevElement = acc[acc.length - 1];
      const nextElement = elements[index + 1];

      if (
          (curr.type === 'br' && (prevElement && prevElement.type === 'ul')) ||
          (curr.type === 'br' && (nextElement && nextElement.type === 'ul'))
      ) {
        return acc;
      }

      return [...acc, curr];
    }, []);
  }



  function parseStringToReactElements(input) {
    // Split input into parts based on headers, bold text, lists, and new lines
    const parts = input.split(/(#### .*?\n|### .*?\n|\*\*.*?\*\*|\n|-\s.*|\d\.\s.*)/g);

    return parts.map((part, index) => {
      if (part.startsWith('#### ')) {
        // Convert #### headers to <h4>
        return <h4 key={index}>{part.slice(5).trim()}</h4>;
      } else if (part.startsWith('### ')) {
        // Convert ### headers to <h3>
        return <h3 key={index}>{part.slice(4).trim()}</h3>;
      } else if (part.startsWith('**') && part.endsWith('**')) {
        // Convert **bold** to <strong>
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      } else if (part.startsWith('\n- ')) {
        // Convert list items to <li> and process nested bold text
        return (
            <li key={index}>
              {parseNestedBold(part.slice(2).trim())}
            </li>
        );
      } else if (part.match(/^\d\..\s.*/)) {
        // Convert ordered list items to <li> and process nested bold text
        return (
            <li key={index}>
              {parseNestedBold(part.slice(3).trim())}
            </li>
        );
      } else if (part === '\n') {

        // Convert new lines to <br>
        return <br key={index} />;
      } else {
        // Regular text with nested bold processing
        return parseNestedBold(part, index);
      }
    });
  }

  function parseNestedBold(text, keyPrefix = '') {
    const boldParts = text.split(/(\*\*.*?\*\*)/g);

    return boldParts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={`${keyPrefix}-${index}`}>{part.slice(2, -2)}</strong>;
      } else {
        return part;
      }
    });
  }








  function handleFeedbackButtonClick(rating) {
    // prevent a message from being rated the same more than once
    if (feedbackSubmitted !== rating) {
      setFeedbackSubmitted(rating);
      submitFeedback(rating, responseId);
    }
  }

  console.log(content)
  const elements = parseStringToReactElements(content);







  return (
    <div className={"message " + type} tabIndex={0}>
      <div className="messageContent">
        { elements
        }
      </div>

      { type === "messageBot" &&
        <>
          <button
              className={"messageFeedbackButton " + (feedbackSubmitted === 1 ? "submitted" : "")}
              onClick={() => handleFeedbackButtonClick(+1)}
              aria-label="Diese Antwort positiv bewerten"
              title="Diese Antwort gefällt mir"
              tabIndex={0}
            >
            <i className="fa-solid fa-thumbs-up"></i>
            </button>
            <button
              className={"messageFeedbackButton " + (feedbackSubmitted === -1 ? "submitted" : "")}
              onClick={() => handleFeedbackButtonClick(-1)}
              aria-label="Diese Antwort negativ bewerten"
              title="Diese Antwort erscheint mir falsch"
              tabIndex={0}
            >
              <i className="fa-solid fa-thumbs-down"></i>
            </button>


        </>
      }
    </div>
  );
}

export default Message;