import './App.scss';
import Control from './components/control/Control';
import {React, useState} from "react";
import MessageBox from './components/messageBox/MessageBox';
import Disclaimer from './components/disclaimer/Disclaimer';
import Headline from './components/headline/Headline';


function App() {
    const [messages, setMessages] = useState([]);
    const [waitingForResponse, setWaitingForResponse] = useState(false);
    const [loggedIn, setLoggedIn] = useState(true);
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [session, setSession] = useState(0);



    const BACKEND_URL_1 = "https://requestpython-v5ok577zva-uc.a.run.app";
    const BACKEND_URL_2 = "https://ec2-18-199-121-25.eu-central-1.compute.amazonaws.com/invoke"
    const CHECKUSER_URL = "https://us-central1-dhbw-chatbot-ddab7.cloudfunctions.net/checkUser";

    const FETCH_ANSWER_FAILED_MESSAGE = "Verbindung zum Server leider gerade nicht möglich.";
    const FETCH_ANSWER_FAILED_RESPONSE_ID = -1;


    /**
     * Adds the content as a user message to the messages state.
     *
     * Requests an answer for the user-question and displays the answer or an error as a bot message.
     *
     * @param question of the input field
     */
    function sendUserMessageAndShowAnswer(question) {


        let messagesBeforeUserMessage = messages;
        let newUserMessage = {type: "messageUser", id: messages.length, content: question};

        setMessages([...messagesBeforeUserMessage, newUserMessage]);
        updateScrollPosition();


        let newBotMessage;


        requestAnswer(question).then(answer => {
            // add a space to "(Quelle: https://www.example.com/)" occurences -> (Quelle: https://www.example.com/ )

            const modifiedAnswer = answer.response.replace(/\(Quelle: [^)]+\)/g, match => match.replace(/\)/, ' )'));



            newBotMessage = {type: "messageBot", id: answer.responseId, content: modifiedAnswer};
        }).finally(() => {
            // add userMessage and botMessage to the messagesBeforeUserMessage
            // (useState can be faulty when retrieving the state directly after setting it)
            setMessages([...messagesBeforeUserMessage, newUserMessage, newBotMessage]);
            updateScrollPosition();
            setWaitingForResponse(false)
        });
    }


    async function requestAnswer(userQuestion) {
        const randomNumber = Math.floor(Math.random() * 9999999) + 1;
        if (session === 0) {
            setSession(randomNumber);
        }

        setWaitingForResponse(true);

        const query = encodeURIComponent(userQuestion);
        const sessionId = session === 0 ? randomNumber : session;
        const url = `http://ec2-18-199-121-25.eu-central-1.compute.amazonaws.com/invoke?query=${query}&session_id=${sessionId}`;

        const requestOptions = {
            method: "POST",
            headers: {
                "accept": "application/json",
            },
            body: "" // Send an empty body
        };

        let response = {
            response: FETCH_ANSWER_FAILED_MESSAGE,
            responseId: randomNumber + 2,
        };

        try {
            const fetchResponse = await fetch(url, requestOptions);
            const data = await fetchResponse.text();
            const parsedData = await JSON.parse(data);

            response = {
                response: parsedData || FETCH_ANSWER_FAILED_MESSAGE,
                responseId: randomNumber + 2,
            };
        } catch (error) {
            console.log("" + error);
        } finally {
            setWaitingForResponse(false);
        }

        return response;
    }

    async function requestAnswerOld(userQuestion) {
        const randomNumber = Math.floor(Math.random() * 9999999) + 1;
        if(session == 0) {
            setSession(randomNumber);
        }



        setWaitingForResponse(true)
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({"query": userQuestion, "session_id": (session == 0 ? randomNumber : session)})
        }
        let response = {
            response: FETCH_ANSWER_FAILED_MESSAGE,
            responseId: randomNumber+2,
        };

        try {
            const fetchResponse = await fetch('http://ec2-18-199-121-25.eu-central-1.compute.amazonaws.com/invoke?query=wer%20ist%20harald%20bendl%3F&session_id=12342134234', requestOptions);
            const data = await fetchResponse.text();
            const parsedData = await JSON.parse(data);


            response = {
                response: parsedData || FETCH_ANSWER_FAILED_MESSAGE,
                responseId: randomNumber+2,
            };
        } catch (error) {
            console.log("" + error);
        } finally {
            setWaitingForResponse(false);
        }

        return response;
    }


    //Benutzt firebase, funktionioert
    async function requestAnswer_firebase(userQuestion) {
        setWaitingForResponse(true)
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({"input": userQuestion})
        }

        // In case of an error there still will be a (error) message
        let response = {
            response: FETCH_ANSWER_FAILED_MESSAGE,
            responseId: FETCH_ANSWER_FAILED_RESPONSE_ID,
        };

        try {
            const fetchResponse = await fetch(BACKEND_URL_1, requestOptions);
            const data = await fetchResponse.text();

            response = {
                response: data || FETCH_ANSWER_FAILED_MESSAGE,
                responseId: FETCH_ANSWER_FAILED_RESPONSE_ID,
            };
        } catch (error) {
            console.log("" + error); // FIXME: Debug log?
        } finally {
            setWaitingForResponse(false);
        }

        return response;
    }


    async function submitFeedback(rating, responseId) {
        /*
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Api-Access": (username + ":" + password)
            },
            body: JSON.stringify({"responseId": responseId, "rating": rating})
        };

        try {
            await fetch(FEEDBACK_URL, requestOptions);
            // Success, no need to return anything
        } catch (error) {
            console.error(error);
        }

         */
    }

    async function updateScrollPosition() {
        setTimeout(() => {
            messageList.scrollTop = messageList.scrollHeight;
            }, 50);

        let messageList = document.getElementById("messageList");
    }

    function handleKeyDown(e) {
        if(e.keyCode === 13) {
            handleLogin();
        }
    }

    function renderForm(waitingForResponse) {return (
        <div className="loginContainer">
            <form className="form" onKeyDown={handleKeyDown}>
                <div className="form__field input__field">
                    <label>Username </label>
                    <input type="text" id="loginUsernameInput" name="uname" placeholder = "Username" required/>

                </div>
                <div className="form__field input__field">
                    <label>Password </label>
                    <input type="password" id="loginPasswordInput" name="pass" placeholder="Password" required/>

                </div>

                <div className="form__field">
                    <button className="loginButton" onClick={handleLogin} type="button">Login</button>
                </div>
                <div className="form__field">

                </div>

            </form>

        </div>
    );}



    async function handleLogin() {


      let usernameValue = document.getElementById("loginUsernameInput").value ?? "";
      let passwordValue = document.getElementById("loginPasswordInput").value ?? "";

      setUsername(usernameValue)
      setPassword(passwordValue)



        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-Api-Access": (usernameValue + ":" + passwordValue)
            },

        }

        // In case of an error there still will be a (error) message

        setWaitingForResponse(true)
        // fetch the answer
        fetch(CHECKUSER_URL, requestOptions).then(res => {

            setWaitingForResponse(false)
            if(res.status === 200) {
                setLoggedIn(true)
                setPassword(passwordValue)
                setUsername(usernameValue)

            } else {
                setWaitingForResponse(false);
                setTimeout(() => {
                    alert("Wrong login credentials!")
                }, 50)

            }
        })

    }


    return (
        <div className="App">

            <Disclaimer/>
            <Headline/>
            {loggedIn ? 
                <div className="chatWindow" role='main'>
                    <MessageBox messages={messages} submitFeedback={submitFeedback} isLoading={waitingForResponse}/>
                    <Control sendUserMessage={sendUserMessageAndShowAnswer}/>
                </div>
                :
                <div className="loginBody">
                    {renderForm(waitingForResponse)}
                </div>
            }
        </div>
    )
}

 // eslint-disable-next-line
const loading = <div className="loadingPos"><div className="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
</div></div>

export default App;
